import React from "react";
import styled, { keyframes } from "styled-components";

const animate = keyframes`
  0%, 100% {
    height: 0;
  }
  50% {
    height: calc(100% - 20px);
  }
`;

const animateLine = keyframes`
  0% {
    top: 20px;
  }
  50% {
    top: calc(100% - 20px);
  }
`;

const ScanWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const QRCode = styled.div`
    position: relative;
    width: 280px;
    height: 230px;
    background-size: 400px;
    @media (max-width: 1280px) {
        width: 220px;
        height: 180px;
    }
    @media (max-width: 992px) {
        width: 180px;
        height: 150px;
    }
    @media (max-width: 768px) {
        width: 150px;
        height: 120px;
    }
    @media (max-width: 576px) {
        width: 220px;
        height: 180px;
    }
    @media (max-width: 440px) {
        width: 170px;
        height: 140px;
    }
`;

const AnimatedQRCode = styled(QRCode)`
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-size: 400px;
        overflow: hidden;
        animation: ${animate} 4s ease-in-out infinite;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 20px;
        width: calc(100% - 40px);
        height: 2px;
        background: #9610d6;
        box-shadow: 0 0 20px #D373FF;
        box-shadow: 0 10px 20px #D373FF;
        animation: ${animateLine} 4s ease-in-out infinite;
    }
`;

const ScanAnimation: React.FC = () => {
    return (
        <div>
            <ScanWrapper>
                <AnimatedQRCode />
            </ScanWrapper>
        </div>
    );
};

export default ScanAnimation;
