import React, { useEffect, useState } from "react";
import { useIsDarkMode } from "hooks/use-media-query";
import { FluidImageData } from "types";
import styled from "styled-components";
import { AuthIcon } from "images/icons/auth-homepage-icons";
import RippleAnimation from "./RippleAnimation";
interface IProps {
    images: FluidImageData[];
    sectionIcon: AuthIcon;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-block: 12%;
    @media(max-width: 576px) {
        margin-block: 30%;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: 440px) {
        svg {
            width: 50px;
            height: 50px;
        }
    }
`;

export const Heading = styled.h1`
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;

    @media (max-width: 332px) {
        font-size: 24px;
    }

    @media (min-width: 1200px) {
        font-size: 55px;
    }

    @media (min-width: 1400px) {
        font-size: 65px;
    }

    @media (prefers-color-scheme: light) {
        color: #9610d6;
    }
`;

export const Paragraph = styled.p`
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    padding: 4px;
    margin-inline: 12%;

    @media (min-width: 332px) {
        font-size: 16px;
    }

    @media (min-width: 440px) {
        font-size: 18px;
    }

    @media (min-width: 768px) {
        font-size: 20px;
    }

    @media (min-width: 1200px) {
        font-size: 24px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    aspect-ratio: 2.4;
    width: 100%;
    margin-top: 5%;
`;

const ContainerStyle = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const Container1 = styled(ContainerStyle)`
    width: 20.83%;
    margin-right: -1%;
`;
const Container2 = styled(ContainerStyle)`
    width: 42.64%;
    position: relative;
`;
const Container3 = styled(ContainerStyle)`
    width: 29.31%;
`;

const Image = styled.img`
    width: 100%;
`;

const AnimationContainer = styled.div`
    z-index: -1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    width: 80%;
    left: 11%;
    @media (min-width: 1500px) {
        scale: 1.1;
    }
    @media (max-width: 1200px) {
        scale: 0.9;
    }
    @media (max-width: 992px) {
        scale: 0.7;
    }
    @media (max-width: 768px) {
        scale: 0.5;
    }
    @media (max-width: 576px) {
        margin-top: -22%;
        left: 23%;
    }
    @media (max-width: 440px) {
        scale: 0.4;
    }
    @media (max-width: 330px) {
        scale: 0.3;
    }
`;

const ImageContainerMobile = styled.div`
    margin-top: 8%;
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const Row1 = styled(Row)`
    position: relative;
`;

const Row2 = styled(Row)`
    margin-top: -25%;
`;

const MobileContainer1 = styled.div`
    width: 40.27%;
    margin-right: -2%;
`;

const MobileContainer2 = styled.div`
    display: flex;
    align-items: center;
    width: 53.33%;
    height: 62%;
    margin-top: 5%;
    position: relative;
`;

const MobileContainer3 = styled.div`
    width: 45%;
`;

export default function Backups(props: IProps) {
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        if (typeof window !== 'undefined') {
            setWindowWidth(window.innerWidth);
            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }
    }, []);
    const isMobileView = windowWidth <= 576;

    const isDarkMode = useIsDarkMode();
    const icon = isDarkMode
        ? props.sectionIcon.iconDark
        : props.sectionIcon.iconLight;
    const authPhone = isDarkMode
        ? props.images[5].fluid.srcSetWebp
        : props.images[4].fluid.srcSetWebp;
    const authIpad = isDarkMode
        ? props.images[7].fluid.srcSetWebp
        : props.images[6].fluid.srcSetWebp;
    let connector = "";
    if(isDarkMode) {
        if(isMobileView) {
            connector = props.images[3].fluid.srcSetWebp;
        }
        else {
            connector = props.images[2].fluid.srcSetWebp;
        }
    }
    else {
        if(isMobileView) {
            connector = props.images[1].fluid.srcSetWebp;
        }
        else {
            connector = props.images[0].fluid.srcSetWebp;
        }
    }
    return (
        <Container id="features">
            <IconContainer>{icon}</IconContainer>
            <Heading>Secure Backups</Heading>
            <Paragraph>
                Auth provides end-to-end encrypted cloud backups so you don't
                have to worry about losing your tokens. Our cryptography has
                been externally audited.
            </Paragraph>
            {!isMobileView ? (
                <ImageContainer>
                    <Container1>
                        <Image
                            fetch-priority="high"
                            srcSet={authPhone}
                            alt="Auth Home screen laptop"
                        />
                    </Container1>
                    <Container2>
                        <Image
                            fetch-priority="high"
                            srcSet={connector}
                            alt="Auth Home screen laptop"
                        />
                        <AnimationContainer>
                            <RippleAnimation />
                        </AnimationContainer>
                    </Container2>
                    <Container3>
                        <Image
                            fetch-priority="high"
                            srcSet={authIpad}
                            alt="Auth Home screen laptop"
                        />
                    </Container3>
                </ImageContainer>
            ) : (
                <ImageContainerMobile>
                    <Row1>
                        <MobileContainer1>
                            <Image
                                fetch-priority="high"
                                srcSet={authPhone}
                                alt="Auth Home screen laptop"
                            />
                        </MobileContainer1>
                        <MobileContainer2>
                            <Image
                                fetch-priority="high"
                                srcSet={connector}
                                alt="Auth Home screen laptop"
                            />
                            <AnimationContainer>
                                <RippleAnimation />
                            </AnimationContainer>
                        </MobileContainer2>
                    </Row1>
                    <Row2>
                        <MobileContainer3>
                            <Image
                                fetch-priority="high"
                                srcSet={authIpad}
                                alt="Auth Home screen laptop"
                            />
                        </MobileContainer3>
                    </Row2>
                </ImageContainerMobile>
            )}
        </Container>
    );
}
