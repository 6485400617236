import React from "react";
import styled, { css, keyframes } from "styled-components";

interface IProps {
    isPasswordAnimation: boolean;
    typingBackgroundColor: string;
}

interface ContainerProps {
    isPasswordAnimation: boolean;
}

const cursorAnimation = keyframes`
    to {
        border-left: 2px solid transparent;
    }
`;

const typingAnimation = keyframes`
    10%, 15%, 30%, 35%, 50%, 55%, 70%, 75%, 90%, 95% {
        width: 0;
    }
    5%, 20%, 25%, 40%, 45%, 60%, 65%, 80%, 85% {
        width: calc(100% + 8px);
    }
`;

const words = keyframes`
    0%, 33% {
        content: "ente";
    }
    34%, 66% {
        content: "stripe";
    }
    67%, 100% {
        content: "github"
    }
`;

const password = keyframes`    
    0%, 50% {
        content: "•••••••";
    }
    51%, 100% {
        content: "•••••••";
    }
`;

const containerkeyframes = keyframes`
    0%, 33% {
        margin-right: 2%;
    }
    34%, 66% {
        margin-right: 0%;
    }
    67%, 100% {
        margin-right: -2%;
    }
`;

const Container = styled.div<ContainerProps>`
    position: absolute;
    top: ${({ isPasswordAnimation }) => (isPasswordAnimation ? "12%" : "36%")};
    right: ${({ isPasswordAnimation }) =>
        isPasswordAnimation ? "65%" : "68%"};
    font-size: 24px;
    animation: ${containerkeyframes} 12s infinite;

    @media (max-width: 1280px) {
        font-size: 20px;
        right: ${({ isPasswordAnimation }) =>
            isPasswordAnimation ? "63%" : "66.5%"};
    }
    @media (max-width: 992px) {
        font-size: 16px;
    }
    @media (max-width: 768px) {
        font-size: 12px;
    }
    @media (max-width: 576px) {
        font-size: 20px;
        top: ${({ isPasswordAnimation }) =>
            isPasswordAnimation ? "10%" : "31.5%"};
    }
    @media (max-width: 440px) {
        font-size: 16px;
        top: ${({ isPasswordAnimation }) =>
            isPasswordAnimation ? "10%" : "30%"};
        right: ${({ isPasswordAnimation }) =>
            isPasswordAnimation ? "63%" : "63%"};
    }
`;

const Text = styled.span<IProps>`
    &::before {
        content: "•••••••";
        animation: ${({ isPasswordAnimation }) =>
            isPasswordAnimation
                ? css`
                      ${password} 20s infinite
                  `
                : css`
                      ${words} 12s infinite
                  `};
    }

    &::after {
        content: "";
        position: absolute;
        width: calc(100% + 8px);
        height: 100%;
        background-color: ${({ typingBackgroundColor }) =>
            typingBackgroundColor};
        border-left: 2px solid;
        right: -8px;
        animation:
            ${cursorAnimation} 0.8s infinite,
            ${typingAnimation} 20s steps(14) infinite;
    }
`;

export default function TypingAnimation(props: IProps) {
    return (
        <Container isPasswordAnimation={props.isPasswordAnimation}>
            <Text
                isPasswordAnimation={props.isPasswordAnimation}
                typingBackgroundColor={props.typingBackgroundColor}
            />
        </Container>
    );
}
