import React from "react";
import styled, { keyframes } from "styled-components";
import WidgetCard from "./WidgetCard";
import { widgetCardIcons } from "images/icons/auth-homepage-icons";

const animationSpeed = "15s";

const scrollAnimation = keyframes`
    0% { transform: translateX(-100%); }
    3% { transform: translateX(-75%); }
    25% {transform: translateX(-75%); }
    28% {transform: translateX(-50%); }
    50% {transform: translateX(-50%);}
    53% {transform: translateX(-25%);}
    75% {transform: translateX(-25%);}
    78% {transform: translateX(0%);}
    100% {transform: translateX(0%);}
`;

const Container = styled.div`
    overflow: hidden;
    width: 100%;
    height: 75%;
    margin-top: -5%;
    @media (max-width: 768px) {
        margin-top: -10%;
    }
    @media (max-width: 576px) {
        margin-top: -5%;
    }
    @media (max-width: 440px) {
        margin-top: -8%;
    }
`;

const Slide = styled.div`
    display: flex;
    height: 100%;
    width: 400%;
`;

const SlideTrack = styled.div`
    animation: ${scrollAnimation} ${animationSpeed} linear infinite;
    display: flex;
    width: 100%;
    height: 100%;
`;

export default function Carousel() {
    return (
        <Container>
            <Slide>
                <SlideTrack>
                    {widgetCardIcons.map(
                        (iconData, index) =>
                            index !== 4 && (
                                <WidgetCard key={index} iconData={iconData} />
                            ),
                    )}
                </SlideTrack>
            </Slide>
        </Container>
    );
}
