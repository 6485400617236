import React from "react";
import styled, { keyframes } from "styled-components";

const ripple = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(178, 11, 255, 0.1),
                0 0 0 20px rgba(178, 11, 255, 0.1),
                0 0 0 40px rgba(178, 11, 255, 0.1),
                0 0 0 60px rgba(178, 11, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(178, 11, 255, 0.1),
                0 0 0 40px rgba(178, 11, 255, 0.1),
                0 0 0 60px rgba(178, 11, 255, 0.1),
                0 0 0 80px rgba(178, 11, 255, 0);
  }
`;

const GlowingSpan = styled.span`
  display: block;
  width: 100px;
  height: 100px;
  margin: 160px auto;
  animation: ${ripple} 1.8s linear infinite;
  border-radius: 50px;
`;

// React Component
const RippleAnimation: React.FC = () => {
  return <GlowingSpan />;
};

export default RippleAnimation;
