import React, { useEffect, useRef, useState } from "react";
import { Container, Heading, IconContainer, Paragraph } from "./Backups";
import { AuthIcon } from "images/icons/auth-homepage-icons";
import { useIsDarkMode } from "hooks/use-media-query";
import { FluidImageData } from "types";
import styled, { keyframes } from "styled-components";

interface IProps {
    images: FluidImageData[];
    sectionIcon: AuthIcon;
}

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-block: 5%;
    @media (max-width: 576px) {
        overflow-x: clip;
        overflow-y: visible;
    }
`;

const Image1 = styled.img`
    width: 95%;
    @media (max-width: 576px) {
        scale: 1.6;
        margin-top: 15%;
        margin-left: 45%;
    }
`;

const moveFromRight = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;

const Image2 = styled.img`
    position: absolute;
    height: 85%;
    left: 73%;
    top: 6%;
    animation: ${moveFromRight} 1s forwards;
    @media (max-width: 576px) {
        scale: 1.1;
        top: 19%;
        margin-left: -6%;
    }
`;

export default function Platform(props: IProps) {
    const isDarkMode = useIsDarkMode();
    const icon = isDarkMode
        ? props.sectionIcon.iconDark
        : props.sectionIcon.iconLight;
    const image1SrcSet = isDarkMode
        ? props.images[1].fluid.srcSetWebp
        : props.images[0].fluid.srcSetWebp;
    const image2SrcSet = isDarkMode
        ? props.images[3].fluid.srcSetWebp
        : props.images[2].fluid.srcSetWebp;

    const [isVisible, setIsVisible] = useState(false);
    const platformRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 },
        );
        if (platformRef.current) {
            observer.observe(platformRef.current);
        }
        return () => {
            if (platformRef.current) {
                observer.unobserve(platformRef.current);
            }
        };
    }, []);

    return (
        <Container ref={platformRef}>
            <IconContainer>{icon}</IconContainer>
            <Heading>Cross platform sync</Heading>
            <Paragraph>
                Auth has an app for every platform. Mobile, desktop and web.
                Your codes sync across all your devices, end-to-end encrypted.
            </Paragraph>

            <ImageContainer>
                <Image1
                    fetch-priority="high"
                    srcSet={image1SrcSet}
                    alt="Auth Home screen laptop"
                />
                {isVisible && (
                    <Image2
                        fetch-priority="high"
                        srcSet={image2SrcSet}
                        alt="Auth Home screen phone"
                    />
                )}
            </ImageContainer>
        </Container>
    );
}
