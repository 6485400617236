import { AuthIcon } from "images/icons/auth-homepage-icons";
import { Container, Heading, IconContainer, Paragraph } from "./Backups";
import React from "react";
import styled from "styled-components";
import { useIsDarkMode } from "hooks/use-media-query";
import { CardStyle, ImageCard } from "./Features1";
import { FluidImageData } from "types";
import ScanAnimation from "./ScanAnimation";
import TypingAnimation from "./TypingAnimation";
import RippleAnimation from "./RippleAnimation";
import { DarkModeProp } from "./WidgetCard";

interface IProps {
    sectionIcon: AuthIcon;
    images: FluidImageData[];
}

const CardContainer = styled.div`
    display: flex;
    margin-block: 2%;
    width: 1200px;
    height: 690px;
    overflow: hidden;

    @media (max-width: 1280px) {
        width: 900px;
        height: 517.5px;
    }
    @media (max-width: 992px) {
        width: 720px;
        height: 414px;
    }
    @media (max-width: 768px) {
        width: 540px;
        height: 310.5px;
    }
    @media (max-width: 576px) {
        flex-direction: column;
        gap: 5%;
        width: 420px;
        height: 1100px;
    }
    @media (max-width: 440px) {
        width: 300px;
        height: 800px;
    }
`;

const Column = styled.div`
    flex-direction: column;
    width: 50%;
    height: 100%;
    @media (max-width: 576px) {
        width: 100%;
        height: 50%;
    }
`;

const Card1 = styled(CardStyle)`
    width: 91.67%;
    height: 100%;
    overflow: hidden;
    @media (max-width: 576px) {
        width: 100%;
        height: 100%;
    }
`;

const Card2 = styled(CardStyle)`
    height: 34.16%;
    @media (max-width: 576px) {
        width: 100%;
        height: 32.9%;
        margin-top: -5%;
    }
`;

const Card3 = styled(CardStyle)`
    height: 59.42%;
    margin-top: 7.42%;
    @media (max-width: 576px) {
        width: 100%;
        height: 57.4%;
    }
`;

export const CardTitle = styled.p`
    display: flex;
    justify-content: center;
    padding-top: 4%;
    font-size: 24px;
    font-weight: 600;
    @media (max-width: 1280px) {
        font-size: 20px;
    }
    @media (max-width: 992px) {
        font-size: 16px;
        padding-top: 2%;
    }
    @media (max-width: 768px) {
        font-size: 12px;
        padding-top: 0%;
    }
    @media (max-width: 576px) {
        font-size: 18px;
        padding-top: 2.5%;
    }
    @media (max-width: 440px) {
        font-size: 14px;
        padding-top: 1%;
    }
`;

const Image = styled.img<DarkModeProp>`
    width: 85%;
    margin-top: ${(props) => (props.isDarkMode ? "5%" : "1%")};
`;

const ImagePhone = styled(Image)`
    margin-top: -5%;
    @media (max-width: 576px) {
        width: 90%;
    }
`;

const ImageSearch = styled(Image)`
    @media (max-width: 1280px) {
        margin-top: ${(props) => (props.isDarkMode ? "3%" : "-2%")};
    }
    @media (max-width: 992px) {
        margin-top: ${(props) => (props.isDarkMode ? "4%" : "-1%")};
    }
    @media (max-width: 768px) {
        margin-top: ${(props) => (props.isDarkMode ? "6%" : "-1%")};
    }
    @media (max-width: 576px) {
        margin-top: ${(props) => (props.isDarkMode ? "8%" : "0%")};
    }
    @media (max-width: 440px) {
        margin-top: ${(props) => (props.isDarkMode ? "7%" : "0%")};
    }
`;

const Image2FA = styled(Image)`
    @media (max-width: 576px) {
        margin-top: ${(props) => (props.isDarkMode ? "10%" : "2%")};
    }
    @media (max-width: 440px) {
        margin-top: ${(props) => (props.isDarkMode ? "8%" : "2%")};
    }
`;

const ScanAnimationContainer = styled.div`
    position: absolute;
    top: 40%;
    @media(max-width: 1280px) {
        top: 39%;
    }
    @media (max-width: 992px) {
        top: 38%;
    }
    @media (max-width: 768px) {
        top: 36%;
    }
    @media (max-width: 576px) {
        top: 40%;
    }
    @media (max-width: 440px) {
        top: 39%;
    }
`;

const ImageContainer = styled.div<DarkModeProp>`
    margin-top: ${(props) => (props.isDarkMode ? "0%" : "8%")};
    @media (max-width: 768px) {
        margin-top: ${(props) => (props.isDarkMode ? "-5%" : "8%")};
    }
`;

const TypingAnimationContainer = styled.div<DarkModeProp>`
    scale: 1.3;
    position: absolute;
    top: ${(props) => (props.isDarkMode ? "39%" : "18%")};
    margin-right: 20%;
    @media (max-width: 1280px) {
        top: ${(props) => (props.isDarkMode ? "26%" : "-12%")};
        margin-right: ${(props) => (props.isDarkMode ? "17%" : "16%")};
    }
    @media (max-width: 992px) {
        top: ${(props) => (props.isDarkMode ? "32%" : "0%")};
        margin-right: 15%;
    }
    @media (max-width: 768px) {
        top: ${(props) => (props.isDarkMode ? "40%" : "-4%")};
    }
    @media (max-width: 576px) {
        scale: 1.2;
        top: ${(props) => (props.isDarkMode ? "48%" : "6%")};
        margin-right: 16%;
    }
    @media (max-width: 440px) {
        scale: 1.1;
        top: ${(props) => (props.isDarkMode ? "43%" : "8%")};
        margin-right: 15%;
    }
`;

const RippleAnimationContainer = styled.div<DarkModeProp>`
    z-index: -1;
    position: absolute;
    scale: 0.8;
    top: ${(props) => (props.isDarkMode ? "-29%" : "-43.5%")};
    @media (max-width: 1280px) {
        scale: 0.6;
        top: ${(props) => (props.isDarkMode ? "-57%" : "-75%")};
    }
    @media (max-width: 992px) {
        scale: 0.48;
        top: ${(props) => (props.isDarkMode ? "-86%" : "-107%")};
    }
    @media (max-width: 768px) {
        scale: 0.35;
        top: ${(props) => (props.isDarkMode ? "-134%" : "-160%")};
    }
    @media (max-width: 576px) {
        scale: 0.55;
        top: ${(props) => (props.isDarkMode ? "-48%" : "-79%")};
    }
    @media (max-width: 440px) {
        scale: 0.35;
        top: ${(props) => (props.isDarkMode ? "-102%" : "-132%")};
    }
`;

export default function Features2(props: IProps) {
    const isDarkMode = useIsDarkMode();
    const icon = isDarkMode
        ? props.sectionIcon.iconDark
        : props.sectionIcon.iconLight;
    const authQRPhoneImage = isDarkMode
        ? props.images[1].fluid.srcSetWebp
        : props.images[0].fluid.srcSetWebp;
    const searchBar = isDarkMode
        ? props.images[3].fluid.srcSetWebp
        : props.images[2].fluid.srcSetWebp;
    const authApps = props.images[4].fluid.srcSetWebp;
    const typingBackgroundColor = isDarkMode ? "#160b1c" : "#FFFFFF";
    return (
        <Container>
            <IconContainer>{icon}</IconContainer>
            <Heading>Import your tokens</Heading>
            <Paragraph>
                You can add tokens to Auth by following one of these methods
            </Paragraph>
            <div className="d-flex justify-content-center">
                <CardContainer>
                    <Column>
                        <Card1>
                            <CardTitle>Scan the QR code</CardTitle>
                            <ImageCard style={{ marginTop: "10%" }}>
                                <ImagePhone
                                    isDarkMode={isDarkMode}
                                    fetch-priority="high"
                                    srcSet={authQRPhoneImage}
                                    alt="Auth offline"
                                />
                                <ScanAnimationContainer>
                                    <ScanAnimation />
                                </ScanAnimationContainer>
                            </ImageCard>
                        </Card1>
                    </Column>

                    <Column>
                        <Card2>
                            <CardTitle>Manually enter the 2FA secret</CardTitle>
                            <ImageContainer isDarkMode={isDarkMode}>
                                <ImageCard>
                                    <ImageSearch
                                        style={{
                                            width: "65%",
                                        }}
                                        isDarkMode={isDarkMode}
                                        fetch-priority="high"
                                        srcSet={searchBar}
                                        alt="search bar"
                                    />
                                    <TypingAnimationContainer
                                        isDarkMode={isDarkMode}
                                    >
                                        <TypingAnimation
                                            isPasswordAnimation={true}
                                            typingBackgroundColor={
                                                typingBackgroundColor
                                            }
                                        />
                                    </TypingAnimationContainer>
                                </ImageCard>
                            </ImageContainer>
                        </Card2>

                        <Card3>
                            <CardTitle>
                                Bulk import from other 2FA apps
                            </CardTitle>
                            <ImageContainer isDarkMode={isDarkMode}>
                                <ImageCard>
                                    <RippleAnimationContainer
                                        isDarkMode={isDarkMode}
                                    >
                                        <RippleAnimation />
                                    </RippleAnimationContainer>
                                    <Image2FA
                                        style={{
                                            width: "75%",
                                        }}
                                        isDarkMode={isDarkMode}
                                        fetch-priority="high"
                                        srcSet={authApps}
                                        alt="auth 2fa apps"
                                    />
                                </ImageCard>
                            </ImageContainer>
                        </Card3>
                    </Column>
                </CardContainer>
            </div>
        </Container>
    );
}
