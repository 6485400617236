import { buttonHoverBoxShadowDark } from "images/icons/auth-homepage-icons";
import * as React from "react";
import styled from "styled-components";

interface IProps {
    text: string;
    icon: React.JSX.Element;
    link: string;
}

const Button = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    width: 180px;
    aspect-ratio: 2.5;
    margin-block: 2%;
    margin-inline: 4%;
    padding: 1%;

    border-radius: 15px;
    border: 1px solid #b37feb;
    background: linear-gradient(
            319deg,
            #b37feb -1.9%,
            rgba(210, 174, 245, 0) 17.34%,
            #efdbff 87.36%
        ),
        #531dab;
    background-blend-mode: soft-light, normal;
    box-shadow:
        0px 2.518px 2.518px 0px #000,
        0px 0px 20.147px 0px rgba(146, 84, 222, 0.32);

    @media (max-width: 576px) {
        width: 125px;
        padding: 3%;
        aspect-ratio: 2.75;
        border-radius: 10px;
    }
    @media (max-width: 440px) {
        width: 110px;
    }
    @media (max-width: 310px) {
        width: 85px;
    }

    &:hover {
        background: linear-gradient(
                319deg,
                #b37feb -1.9%,
                rgba(210, 174, 245, 0) 17.34%,
                #efdbff 87.36%
            ),
            linear-gradient(180deg, #140a24 0%, #531dab 100%);
    }
`;

const ButtonIcon = styled.div`
    display: flex;
    align-items: center;
    svg {
        width: 75%;
    }
    @media (max-width: 576px) {
        width: 35px;
        height: 35px;
    }
    @media (max-width: 440px) {
        width: 25px;
        height: 25px;
    }
`;

const ButtonText = styled.div`
    font-weight: bold;
    font-size: 18px;
    color: white;
    @media (max-width: 576px) {
        font-size: 14px;
    }
    @media (max-width: 440px) {
        font-size: 10px;
    }
`;

export const StarAnimation = styled.div<{ boxShadow: string }>`
    width: 2px;
    height: 2px;
    background: transparent;
    animation: animStar 10s linear infinite;
    box-shadow: ${({ boxShadow }) => boxShadow};

    @keyframes animStar {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-2000px);
        }
    }
`;

export default function DownloadButton(props: IProps) {
    const [isHovered, setIsHovered] = React.useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <Button
            href={props.link}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <ButtonIcon>{props.icon}</ButtonIcon>
            <ButtonText>{props.text}</ButtonText>
            {isHovered && (
                <div
                    style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                    }}
                >
                    <StarAnimation boxShadow={buttonHoverBoxShadowDark} />
                </div>
            )}
        </Button>
    );
}
